
import { defineComponent, PropType, ref } from 'vue';
import { loginApi } from '@/api';
import router from '@/router';

export default defineComponent({
    props: {
        joinType: {
            type: String as PropType<'login'|'logo'>,
            default: 'login'
        },
        akuvoxUrl: {
            type: String,
            default: ''
        }
    },
    setup(props) {
        const isShowJumpPage = ref(false);
        const jumpUrl = ref('');

        function goToAkuvox() {
            if (props.akuvoxUrl.match(/communityhome/)) {
                localStorage.setItem('InstallerLoginType', '1');
            }
            router.push(props.akuvoxUrl);
        }

        function goToAkubola() {
            loginApi.getSmartHomeUri((res: {Url: string}) => {
                const unityUrl = `${process.env.VUE_APP_MIDDLE_URL}?joinType=${props.joinType}&akuvoxUrl=${props.akuvoxUrl}`;
                jumpUrl.value = `${res.Url}&unity_url=${encodeURIComponent(unityUrl) as string}`;
                window.location.href = jumpUrl.value;
                // const isOpen = window.open(jumpUrl.value);
                // if (!isOpen) {
                //     isShowJumpPage.value = true;
                // }
            });
        }
        return {
            goToAkuvox,
            goToAkubola,
            isShowJumpPage,
            jumpUrl
        };
    }
});
